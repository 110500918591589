<template>
    <div>
        <!-- begin::Content header -->
        <div class="position-absolute top-0 right-0 mt-5 mb-15 mb-lg-0 py-5 px-10">
            <span class="font-weight-bold font-size-3 text-dark-60">
                {{ $t('AUTH.GENERAL.NO_ACCOUNT') }}
            </span>
            <router-link
                class="font-weight-bold font-size-3 ml-2"
                :to="{ name: 'register' }"
            >
                {{ $t('AUTH.REGISTER.TITLE') }}
            </router-link>
        </div>
        <!-- end::Content header -->

        <!-- begin::Signin -->
        <div>
            <div class="text-center mb-10">
                <h3 class="font-size-h1">
                    {{ $t('AUTH.LOGIN.TITLE') }}
                </h3>
                <p class="text-muted font-weight-semi-bold">
                    {{ $t('AUTH.LOGIN.DESC') }}
                </p>
            </div>

            <!-- begin::Form -->
            <b-form class="form" @submit.stop.prevent="onSubmit">
                <div
                    role="alert"
                    :class="{ show: errors.length }"
                    class="alert fade alert-danger"
                >
                    <div v-for="(error, i) in errors"
                         :key="i"
                         class="alert-text"
                    >
                        {{ error }}
                    </div>
                </div>

                <b-form-group
                    id="example-input-group-1"
                    label=""
                    label-for="username"
                >
                    <b-form-input
                        id="username"
                        v-model="$v.form.username.$model"
                        class="form-control form-control-solid h-auto py-5 px-6"
                        name="username"
                        :placeholder="$t('AUTH.INPUT.USERNAME')"
                        :state="validateState('username')"
                        aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ $t('AUTH.VALIDATION.REQUIRED', {name: $t('AUTH.INPUT.USERNAME')}) }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    id="example-input-group-2"
                    label=""
                    label-for="password"
                >
                    <b-form-input
                        id="password"
                        v-model="$v.form.password.$model"
                        class="form-control form-control-solid h-auto py-5 px-6"
                        type="password"
                        name="password"
                        :placeholder="$t('AUTH.INPUT.PASSWORD')"
                        :state="validateState('password')"
                        aria-describedby="input-2-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-2-live-feedback">
                        {{ $t('AUTH.VALIDATION.REQUIRED', {name: $t('AUTH.INPUT.PASSWORD')}) }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <!--begin::Action-->
                <div
                    class="form-group d-flex flex-wrap justify-content-between align-items-center"
                >
                    <button
                        id="loginButton"
                        ref="kt_login_signin_submit"
                        type="submit"
                        :disabled="$wait.waiting('login')"
                        class="btn btn-primary font-weight-bold px-9 py-4 font-size-3 btn-block"
                    >
                        {{ $t('AUTH.LOGIN.BUTTON') }}
                    </button>

                    <a
                        id="kt_login_forgot"
                        href="#"
                        class="text-dark-60 text-hover-primary my-4 ml-auto"
                    >
                        {{ $t('AUTH.FORGOT.TITLE') }}
                    </a>
                </div>
                <!-- end::Action -->
            </b-form>
            <!-- end::Form -->
        </div>
    <!-- end::Signin -->
    </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { minLength, required } from 'vuelidate/lib/validators'
import { waitFor } from 'vue-wait'

export default {
    name: 'Login',
    mixins: [validationMixin],

    data: () => ({
        form: {
            username: '',
            password: ''
        }
    }),

    validations: {
        form: {
            username: {
                required
            },
            password: {
                required,
                minLength: minLength(3)
            }
        }
    },

    computed: {
        ...mapState({
            errors: state => state.auth.errors || []
        })
    },

    methods: {
        validateState (name) {
            const { $dirty, $error } = this.$v.form[name]
            return $dirty ? !$error : null
        },
        resetForm () {
            this.form = {
                username: null,
                password: null
            }

            this.$nextTick(() => {
                this.$v.$reset()
            })
        },

        onSubmit: waitFor('login', async function () {
            this.$v.form.$touch()

            if (this.$v.form.$anyError) {
                return
            }

            const username = this.$v.form.username.$model
            const password = this.$v.form.password.$model

            // send login request
            await this.$store.dispatch('auth/login', { username, password })
            await this.$router.push({ name: 'overview' })
        })

    }
}
</script>
